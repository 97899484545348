import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000/api';

const TOKEN_KEY = 'jwtToken';

const decodeToken = (token) => {
  try {
    return jwtDecode(token);
  } catch (error) {
    console.error('Error decoding token:', error);
    return null;
  }
};

const AuthService = {
  async register(registrationDetails) {
    try {
      const response = await axios.post(`${API_BASE_URL}/register`, registrationDetails);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : 'Registration failed';
    }
  },

  async login(loginDetails) {
    try {
      const response = await axios.post(`${API_BASE_URL}/auth/login`, loginDetails);
      const token = response.data.token;
      if (token) {
        localStorage.setItem(TOKEN_KEY, token);
      }
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : 'Login failed';
    }
  },

  async getRegistration(filters = {}) {
    try {
      const response = await axios.get(`${API_BASE_URL}/register`, {
        params: filters,
      });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : 'Failed to get registration';
    }
  },

  getToken() {
    return localStorage.getItem(TOKEN_KEY);
  },

  getUserId() {
    const token = this.getToken();
    if (token) {
      const decodedToken = decodeToken(token);
      return decodedToken ? decodedToken.userId : null;
    }
    return null;
  },

  isAuthenticated() {
    return !!localStorage.getItem(TOKEN_KEY);
  },

  logout() {
    localStorage.removeItem(TOKEN_KEY);
  }
};

export default AuthService;
