import React, { useEffect, useState } from 'react';
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Table,
  Button,
  ButtonGroup,
  Input,
  Badge,
  FormGroup,
  Label,
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faFunnel,
  faDownload,
  faSync,
  faFilter,
} from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import "react-datepicker/dist/react-datepicker.css";
import './Tickets.scss';
import { TicketService, CityService } from '../../../services/api';
import TicketCard from './Ticket';
import TeamModal from './TeamModal';

const Tickets = () => {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(24);
  const [totalPages, setTotalPages] = useState(0);
  const [cities, setCities] = useState([]);
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const [ticketTypes, setTicketTypes] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filters, setFilters] = useState({
    search: '',
    status: '',
    ticketType: '',
    city: '',
    startDate: null,
    endDate: null
  });
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editedTicket, setEditedTicket] = useState({
    participantName: '',
    participantEmail: '',
    participantPhone: '',
    tShirtSize: '',
  });
  const [teamModalOpen, setTeamModalOpen] = useState(false);
  const [teamTickets, setTeamTickets] = useState([]);

  const toggleModal = (ticket = null) => {
    setSelectedTicket(ticket);
    if (ticket) {
      setEditedTicket({
        participantName: ticket.participantName,
        participantEmail: ticket.participantEmail,
        participantPhone: ticket.participantPhone,
        tShirtSize: ticket.tShirtSize,
      });
    }
    setIsModalOpen(!isModalOpen);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedTicket(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const fetchTickets = async () => {
    setLoading(true);
    try {
      const data = await TicketService.getTickets(filters, currentPage, itemsPerPage);
      console.log(data.tickets);
      setTickets(data.tickets);
      setTotalPages(Math.ceil(data.total / itemsPerPage));
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateTicket = async () => {
    try {
      // Add API call to update ticket
      await TicketService.updateTicket(selectedTicket._id, editedTicket);
      // Refresh tickets
      fetchTickets();
      toggleModal();
    } catch (error) {
      console.error('Error updating ticket:', error);
    }
  };

  const handlePrint = (ticket) => {
    // Add API call to print ticket
    console.log('Print ticket:', ticket);
  };

  const viewTeam = async (ticket) => {
    try {
      const response = await TicketService.getTickets({
        registrationId: ticket.registrationId
      });
      setTeamTickets(response.tickets);
      setTeamModalOpen(true);
    } catch (error) {
      console.error('Error fetching team tickets:', error);
    }
  };

  const handleCancel = (ticket) => {
    // Add API call to cancel ticket
    console.log('Cancel ticket:', ticket);
  };

  const handleMarkEntry = async (ticket) => {
    // Add API call to mark entry
    await TicketService.markEntry(ticket._id);
    ticket.status = 'present';
    setTickets([...tickets]);
  };

  const handleFilterChange = async (field, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
    await fetchFilteredTickets({ ...filters, [field]: value });
  };

  const fetchFilteredTickets = async (currentFilters) => {
    try {
      const response = await TicketService.getTickets(currentFilters, currentPage, itemsPerPage);
      setTickets(response.tickets);
      setTotalPages(Math.ceil(response.total / itemsPerPage));
    } catch (err) {
      setError(err.message || 'Failed to fetch tickets');
    }
  };

  const fetchCities = async () => {
    try {
      const response = await CityService.getAllCities();
      setCities(response);
    } catch (err) {
      setError(err.message || 'Failed to fetch cities');
    }
  };

  const fetchTicketTypes = async () => {
    try {
      const response = await TicketService.getAllTicketTypes();
      setTicketTypes(response);
    } catch (err) {
      setError(err.message || 'Failed to fetch ticket types');
    }
  };

  const getStatusBadgeColor = (status) => {
    switch (status.toLowerCase()) {
      case 'pending':
        return 'warning';
      case 'confirmed':
        return 'success';
      case 'cancelled':
        return 'danger';
      case 'present':
        return 'info';
      default:
        return 'primary';
    }
  };

  const handleExport = () => {
    let csvRows = ['Participant Name,ID,City,Participant Email,Participant Phone,Ticket Type,T-Shirt Size,Price,Status,Booked At'];
    
    tickets.forEach(ticket => {
      const row = [
        ticket.participantName,
        ticket._id,
        ticket.cityId?.name || '',
        ticket.participantEmail,
        ticket.participantPhone,
        ticket.ticketType?.name || '',
        ticket.tShirtSize,
        ticket.price,
        ticket.status,
        new Date(ticket.createdAt).toLocaleString()
      ].map(field => `"${field}"`).join(',');
      
      csvRows.push(row);
    });

    const csvContent = csvRows.join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'tickets.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleRefresh = () => {
    fetchTickets();
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPaginationItems = () => {
    const items = [];
    const maxPagesToShow = 5;
    let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    if (endPage - startPage + 1 < maxPagesToShow) {
      startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }

    // Previous button
    items.push(
      <PaginationItem key="prev" disabled={currentPage === 1}>
        <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)} />
      </PaginationItem>
    );

    // First page
    if (startPage > 1) {
      items.push(
        <PaginationItem key={1}>
          <PaginationLink onClick={() => handlePageChange(1)}>1</PaginationLink>
        </PaginationItem>
      );
      if (startPage > 2) {
        items.push(<PaginationItem key="ellipsis1" disabled><PaginationLink>...</PaginationLink></PaginationItem>);
      }
    }

    // Page numbers
    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <PaginationItem key={i} active={currentPage === i}>
          <PaginationLink onClick={() => handlePageChange(i)}>{i}</PaginationLink>
        </PaginationItem>
      );
    }

    // Last page
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        items.push(<PaginationItem key="ellipsis2" disabled><PaginationLink>...</PaginationLink></PaginationItem>);
      }
      items.push(
        <PaginationItem key={totalPages}>
          <PaginationLink onClick={() => handlePageChange(totalPages)}>{totalPages}</PaginationLink>
        </PaginationItem>
      );
    }

    // Next button
    items.push(
      <PaginationItem key="next" disabled={currentPage === totalPages}>
        <PaginationLink next onClick={() => handlePageChange(currentPage + 1)} />
      </PaginationItem>
    );

    return items;
  };

  useEffect(() => {
    fetchCities();
    fetchTicketTypes();
    fetchFilteredTickets(filters);
  }, [filters]);

  useEffect(() => {
    fetchTickets();
  }, [filters, currentPage, itemsPerPage]);

  if (error) return <p>Error: {error}</p>;
  return (
    <Container fluid className="tickets-page">
      <PageTitle
        heading="Tickets Management"
        subheading="View and manage event tickets"
        icon="pe-7s-ticket icon-gradient bg-mean-fruit"
      />

      <Card className="mb-3">
        <CardBody>
          <Row className="align-items-center mb-3">
            <Col md={3}>
              <div className="search-box">
                <Input
                  type="text"
                  placeholder="Search registrations..."
                  value={filters.search}
                  onChange={(e) => handleFilterChange('search', e.target.value)}
                />
                <FontAwesomeIcon icon={faSearch} className="search-icon" />
              </div>
            </Col>
            <Col md={5} className="text-right">
              <ButtonGroup>
                <Button
                  color="secondary"
                  onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
                >
                  <FontAwesomeIcon icon={faFilter} className="me-2" />
                  Filters
                </Button>
                <Button color="secondary" onClick={handleExport}>
                  <FontAwesomeIcon icon={faDownload} className="me-2" />
                  Export
                </Button>
                <Button color="secondary" onClick={handleRefresh}>
                  <FontAwesomeIcon icon={faSync} className="me-2" />
                  Refresh
                </Button>
              </ButtonGroup>
            </Col>
            <Col md={4}>
                <FormGroup>
                  <Label>Ticket Types</Label>
                  <div className="ticket-type-list">
                    {ticketTypes.map(type => (
                      <Badge
                        key={type._id}
                        color={type.color}
                        className="me-2 mb-2"
                        style={{ backgroundColor: type.color, color: '#fff' }}
                      >
                        {type.name}
                      </Badge>
                    ))}
                  </div>
                </FormGroup>
              </Col>
          </Row>

          {showAdvancedFilters && (
            <Row className="mb-4">
              <Col md={3}>
                <FormGroup>
                  <Label>Search</Label>
                  <Input
                    type="text"
                    value={filters.search}
                    onChange={(e) => handleFilterChange('search', e.target.value)}
                    placeholder="Search by name or email"
                  />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label>Status</Label>
                  <Input
                    type="select"
                    value={filters.status}
                    onChange={(e) => handleFilterChange('status', e.target.value)}
                  >
                    <option value="">All Status</option>
                    <option value="pending">Pending</option>
                    <option value="confirmed">Confirmed</option>
                    <option value="cancelled">Cancelled</option>
                    <option value="present">Present</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label>Ticket Type</Label>
                  <Input
                    type="select"
                    value={filters.ticketType}
                    onChange={(e) => handleFilterChange('ticketType', e.target.value)}
                  >
                    <option value="">All Ticket Types</option>
                    {ticketTypes.map(type => (
                      <option key={type._id} value={type._id}>{type.name}</option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label>City</Label>
                  <Input
                    type="select"
                    value={filters.city}
                    onChange={(e) => handleFilterChange('city', e.target.value)}
                  >
                    <option value="">All Cities</option>
                    {cities.map(city => (
                      <option key={city._id} value={city._id}>{city.name}</option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
          )}

          <Row>
            {tickets.map(ticket => (
              <Col md={4} key={ticket._id} className="mb-3">
                <TicketCard
                  ticket={ticket}
                  onPrint={handlePrint}
                  onViewTeam={viewTeam}
                  cities={cities}
                  onCancel={handleCancel}
                  onMarkEntry={handleMarkEntry}
                  onEdit={handleUpdateTicket}
                  onTicketUpdated={fetchTickets}
                />
              </Col>
            ))}
          </Row>

          <Row className="mt-4">
            <Col className="d-flex justify-content-center">
              <Pagination>
                {renderPaginationItems()}
              </Pagination>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <TeamModal
        isOpen={teamModalOpen}
        toggle={() => setTeamModalOpen(!teamModalOpen)}
        onMarkEntry={handleMarkEntry}
        onViewTeam={viewTeam}
        teamTickets={teamTickets}
        getStatusBadgeColor={getStatusBadgeColor}
      />

    </Container>
  );
};

export default Tickets;