import {
  faHome,
  faUserFriends,
  faTicketAlt,
  faCity,
  faCog,
  faUserPlus,
  faGift
} from '@fortawesome/free-solid-svg-icons';

export const MainNav = [
  {
    icon: faHome,
    label: 'Dashboard',
    to: "/admin/dashboard",
  },
  {
    icon: faTicketAlt,
    label: "Tickets",
    to: "/admin/tickets",
  },
  {
    icon: faUserPlus,
    label: "Registrations",
    to: "/admin/registrations",
  },
  {
    icon: faUserFriends,
    label: "Users",
    to: "/admin/users",
  },
  {
    icon: faCity,
    label: "Cities",
    to: "/admin/cities",
  },
  {
    icon: faGift,
    label: "Donations",
    to: "/admin/donations",
  },
  {
    icon: faCog,
    label: "Settings",
    to: "/admin/settings",
  },
];

export const AdminNav = [
  {
    icon: faGift,
    label: 'Coupon Management',
    to: '/admin/coupons',
  },
];
