import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';

import TicketCard from './Ticket';
import { RegistrationService } from '../../../services/api';

const TeamModal = ({
  isOpen,
  toggle,
  teamTickets,
  onMarkEntry,
  onViewTeam,
  getStatusBadgeColor,
}) => {

  const [registrationDetails, setRegistrationDetails] = useState(null);

  useEffect(() => {
    getRegistrationDetails();
  }, [teamTickets]);

  const getRegistrationDetails = async () => {
    const registrationId = teamTickets[0]?.registrationId;
    if (!registrationId) return null;
    const response = await RegistrationService.getRegistrationDetails(registrationId);
    setRegistrationDetails(response);
    return response;
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>
        Team Members ({teamTickets.length})
      </ModalHeader>
      <ModalBody>
        Registration ID: {registrationDetails?._id} <br />
        Total Amount: {registrationDetails?.totalAmount} <br />
        Donation: {registrationDetails?.donation} <br />
        {teamTickets.map((ticket, index) => (
          <TicketCard
            className="mb-3"
            key={index}
            ticket={ticket}
            onMarkEntry={onMarkEntry}
          />
        ))}
      </ModalBody>
    </Modal>
  );
};

export default TeamModal;
