import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';

// Layout
import AppHeader from '../AppHeader';
import AppSidebar from '../AppSidebar';
import AppFooter from '../AppFooter';

// Public Pages
import CitySelection from '../../Pages/Public/CitySelection';
import Register from '../../Pages/Public/Register';
import RegistrationSuccess from '../../Pages/Public/RegistrationSuccess';
import Login from '../../Pages/Public/Login';

// Admin Pages
import Registrations from '../../Pages/Admin/Registrations';
import Tickets from '../../Pages/Admin/Tickets';
import Users from '../../Pages/Admin/Users';
import Settings from '../../Pages/Admin/Settings';
import Dashboard from '../../Pages/Admin/Dashboard';
import Cities from '../../Pages/Admin/Cities';
import CouponManagement from '../../Pages/Admin/CouponManagement';
import Donations from '../../Pages/Admin/Donations';

const AppMain = () => {
  // Check if the current route is a public page
  const isPublicPage = !(window.location.pathname.startsWith('/admin'));

  return (
    <Fragment>
      {!isPublicPage && <AppHeader />}
      <div className={`app-main ${isPublicPage ? 'public-page' : ''}`}>
        {!isPublicPage && <AppSidebar />}
        <div className={`app-main__outer ${isPublicPage ? 'w-100' : ''}`}>
          <div className="app-main__inner">
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/register/:cityId" component={Register} />
              <Route exact path="/registration-success" component={RegistrationSuccess} />
              <Route exact path="/admin/registrations" component={Registrations} />
              <Route exact path="/admin/registrations#/" component={Registrations} />
              <Route path="/admin/tickets" component={Tickets} />
              <Route path="/admin/users" component={Users} />
              <Route path="/admin/settings" component={Settings} />
              <Route exact path="/admin/dashboard" component={Dashboard} />
              <Route exact path="/admin/cities" component={Cities} />
              <Route exact path="/admin/donations" component={Donations} />
              <Route exact path="/" component={CitySelection} />
              <Route exact path="/admin/coupon-management" component={CouponManagement} />
            </Switch>
          </div>
          {!isPublicPage && <AppFooter />}
        </div>
      </div>
    </Fragment>
  );
};

export default AppMain;
