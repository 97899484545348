import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import './Footer.scss';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <Container>
        <div className="footer-content">
          <div className="footer-top">
            <div className="logo-section">
              <img src="https://ordindia.in/wp-content/themes/ordi_india_2018/images/ordi-india.png" alt="ORD India Logo" />
              <img src="/images/logo.png" alt="Race for 7 Logo" />
            </div>
            <div className="social-links">
              <a href="https://facebook.com/ORDIndia" target="_blank" rel="noopener noreferrer">
                <FaFacebook />
              </a>
              <a href="https://twitter.com/ord_india" target="_blank" rel="noopener noreferrer">
                <FaTwitter />
              </a>
              <a href="https://instagram.com/ord_india" target="_blank" rel="noopener noreferrer">
                <FaInstagram />
              </a>
              <a href="https://linkedin.com/company/organization-for-rare-diseases-india" target="_blank" rel="noopener noreferrer">
                <FaLinkedin />
              </a>
            </div>
          </div>

          <div className="footer-middle">
            <div className="footer-links">
              <h5>About</h5>
              <ul>
                <li>
                  <a href="https://ordindia.org" target="_blank" rel="noopener noreferrer">
                    ORD India
                  </a>
                </li>
                <li>
                  <a href="https://racefor7.com" target="_blank" rel="noopener noreferrer">
                    Race for 7
                  </a>
                </li>
                <li>
                  <a href="https://ordindia.org/rare-disease-info" target="_blank" rel="noopener noreferrer">
                    Rare Disease Info
                  </a>
                </li>
              </ul>
            </div>

            <div className="footer-links">
              <h5>Support</h5>
              <ul>
                <li>
                  <a href="https://ordindia.org/contact" target="_blank" rel="noopener noreferrer">
                    Contact Us
                  </a>
                </li>
                <li>
                  <a href="https://ordindia.org/donate" target="_blank" rel="noopener noreferrer">
                    Donate
                  </a>
                </li>
                <li>
                  <a href="https://ordindia.org/volunteer" target="_blank" rel="noopener noreferrer">
                    Volunteer
                  </a>
                </li>
              </ul>
            </div>

            <div className="footer-links">
              <h5>Legal</h5>
              <ul>
                <li>
                  <a href="/terms" target="_blank" rel="noopener noreferrer">
                    Terms & Conditions
                  </a>
                </li>
                <li>
                  <a href="/privacy" target="_blank" rel="noopener noreferrer">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a href="/refund" target="_blank" rel="noopener noreferrer">
                    Refund Policy
                  </a>
                </li>
              </ul>
            </div>

            <div className="footer-links">
              <h5>Address</h5>
              <ul>
                <li>Organization for Rare Diseases India</li>
                <li>No. 180/2, Flat No.114, Swarna Silicon Minos</li>
                <li>Basavanna Nagar, Hoodi, Bengaluru - 560048</li>
                <li>Karnataka, India</li>
              </ul>
            </div>
          </div>

          <div className="footer-bottom">
            <p>
              © {currentYear} Organization for Rare Diseases India. All rights reserved.
            </p>
            <p>
              Race for 7 is an initiative to raise awareness for 7000 rare diseases that affect 70 million rare disease patients in India
            </p>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
