import React, { useState, useEffect } from 'react';
import {
  Container,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
  Alert,
  Badge
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBuilding,
  faUser,
  faEnvelope,
  faPhone,
  faCalendar,
  faTicket,
  faTshirt,
  faPlus,
  faTicketAlt,
  faReceipt,
  faCheckCircle,
  faLock,
  faTag
} from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import './Register.scss';
import './FormElements.scss';
import { CityService, TicketService, PaymentService, RegistrationService } from '../../services/api';
import Footer from '../../components/Footer/Footer';

const Register = ({ match }) => {
  const history = useHistory();
  const [participants, setParticipants] = useState([{
    name: '',
    email: '',
    phone: '',
    dateOfBirth: '',
    ticketType: '',
    tShirtSize: 'M',
    gender: '',
    donation: 100
  }]);
  const [organization, setOrganization] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [availableTicketTypes, setAvailableTicketTypes] = useState([]);
  const [cityDetails, setCityDetails] = useState(null);
  const cityId = match.params.cityId;

  useEffect(() => {
    const fetchCityData = async () => {
      try {
        const currentCity = await CityService.getCityById(cityId);
        setCityDetails(currentCity);
        setAvailableTicketTypes(currentCity.ticketTypes);
      } catch (error) {
        console.error('Error fetching city data:', error);
      }
    };
    fetchCityData();
  }, [cityId]);

  const ticketTypes = availableTicketTypes.reduce((acc, ticketType) => {
    acc[ticketType._id] = { price: ticketType.price, available: true, name: ticketType.name };
    return acc;
  }, {});

  useEffect(() => {
    let total = participants.reduce((sum, participant) => {
      return sum + (ticketTypes[participant.ticketType]?.price || 0) + parseInt(participant.donation);
    }, 0);

    if (referralCode) {
      const discountAmount = total * 0.1;
      setDiscount(discountAmount);
      total -= discountAmount;
    } else {
      setDiscount(0);
    }

    setTotalAmount(total);
  }, [participants, referralCode, ticketTypes]);

  const handleParticipantChange = (index, field, value) => {
    const newParticipants = [...participants];
    newParticipants[index] = {
      ...newParticipants[index],
      [field]: value
    };
    setParticipants(newParticipants);
  };

  const addParticipant = () => {
    setParticipants([...participants, {
      name: '',
      email: '',
      phone: '',
      dateOfBirth: '',
      ticketType: '',
      tShirtSize: 'M',
      gender: '',
      donation: 0
    }]);
  };

  const handleRemoveParticipant = (index) => {
    const newParticipants = [...participants];
    newParticipants.splice(index, 1);
    setParticipants(newParticipants);
  };

  const initializeRazorpay = async (orderData, registrationId) => {
    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID,
      amount: orderData.amount * 100,
      currency: orderData.currency,
      name: 'Race for 7',
      description: 'Registration Payment',
      order_id: orderData.id,
      handler: async (response) => {
        console.log(response);
        try {
          setLoading(true);
          const verificationData = {
            orderId: orderData.id,
            paymentId: response.razorpay_payment_id,
            signature: response.razorpay_signature,
            registrationId: registrationId,
            registrationData: {
              id: registrationId,
              cityId,
              participants: participants.map(p => ({
                name: p.name,
                email: p.email,
                phone: p.phone,
                ticketType: p.ticketType,
                ticketPrice: ticketTypes[p.ticketType].price,
                tShirtSize: p.tShirtSize,
                dateOfBirth: p.dateOfBirth,
                gender: p.gender,
                donation: p.donation
              })),
              organization,
              referralCode,
              couponCode,
              totalAmount: totalAmount,
              donationAmount: participants.reduce((sum, participant) => sum + participant.donation, 0)
            }
          };
          setLoading(true);
          const result = await PaymentService.verifyPayment(verificationData);
          if (result.success) {
            setSuccessMessage('Registration successful!');
            history.push('/registration-success', { 
              registrationDetails: result.registration 
            });
          } else {
            setError('Payment verification failed');
          }
        } catch (error) {
          setError(error.message || 'Payment verification failed');
        } finally {
          setLoading(false);
        }
      },
      prefill: {
        name: participants[0].name,
        email: participants[0].email,
        contact: participants[0].phone
      },
      theme: {
        color: '#e85c41'
      }
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    setSuccessMessage(null);

    const isValid = participants.every(participant =>
      participant.name &&
      participant.email &&
      participant.phone &&
      participant.ticketType &&
      participant.gender
    );

    if (!isValid) {
      setError('Please fill in all required fields for each participant');
      setLoading(false);
      return;
    }

    try {
      // Create payment order
      const regDetails = await RegistrationService.register({
        amount: totalAmount,
        currency: 'INR',
        registrationData: {
          cityId,
          participants: participants.map(p => ({
            name: p.name,
            email: p.email,
            phone: p.phone,
            ticketType: p.ticketType,
            ticketPrice: ticketTypes[p.ticketType].price,
            tShirtSize: p.tShirtSize,
            dateOfBirth: p.dateOfBirth,
            gender: p.gender,
            donation: p.donation
          })),
          organization,
          referralCode,
          couponCode,
          totalAmount: totalAmount,
          donationAmount: participants.reduce((sum, participant) => sum + participant.donation, 0)
        }
      });

      // Initialize Razorpay
      await initializeRazorpay(regDetails?.orderData, regDetails.registration._id);
    } catch (error) {
      setError(error.message || 'Registration failed');
      setLoading(false);
    }
  };

  return (
    <div className="register-page">
      {loading && (
        <div className="loading-overlay">
          <div className="spinner"></div>
          <div className="loading-text">Processing your registration...</div>
        </div>
      )}
      <Container>
        <img className="banner" src='https://registration.ordindia.in/images/banner.jpg' width={'100%'} alt="Event banner" />
        {/* <h1 className="text-center mb-4">Event Registration</h1> */}
        {error && <Alert color="danger">{error}</Alert>}
        {successMessage && <Alert color="success">{successMessage}</Alert>}
        <Row className="mt-4">
          <Col lg={8}>
            <Card>
              <CardBody>
                <div className="registration-header">
                  <FontAwesomeIcon icon={faTicketAlt} className="mr-2" />
                  <h2>Event Registration</h2>
                </div>
                <p className="registration-subtitle">Register for our upcoming event</p>

                <Form onSubmit={handleSubmit}>
                  <p>City: {cityDetails?.name || 'Loading...'}</p>
                  <p>Date: {cityDetails?.eventDates[0] || 'Loading...'}</p>
                  <p>Venue: {cityDetails?.venueAddress || 'Loading...'}</p>
                  <div className="organization-section">
                    <div className="section-header">
                      <FontAwesomeIcon icon={faBuilding} className="mr-2" />
                      <Label>Organization (Optional)</Label>
                    </div>
                    <Input
                      type="text"
                      placeholder="Enter organization name"
                      value={organization}
                      onChange={(e) => setOrganization(e.target.value)}
                    />
                  </div>

                  {participants.map((participant, index) => (
                    <Card key={index} className="participant-card">
                      <CardBody>
                        <h3 className="participant-header">
                          <FontAwesomeIcon icon={faUser} />
                          Participant {index + 1}
                        </h3>
                        <Row>
                          <Col md={6}>
                            <FormGroup>
                              <div className="input-icon">
                                <FontAwesomeIcon icon={faUser} />
                                <Label>Name</Label>
                              </div>
                              <Input
                                type="text"
                                placeholder="Full Name"
                                value={participant.name}
                                onChange={(e) => handleParticipantChange(index, 'name', e.target.value)}
                                required
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <div className="input-icon">
                                <FontAwesomeIcon icon={faEnvelope} />
                                <Label>Email</Label>
                              </div>
                              <Input
                                type="email"
                                placeholder="Email"
                                value={participant.email}
                                onChange={(e) => handleParticipantChange(index, 'email', e.target.value)}
                                required
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <div className="input-icon">
                                <FontAwesomeIcon icon={faPhone} />
                                <Label>Phone</Label>
                              </div>
                              <Input
                                type="tel"
                                placeholder="Phone Number"
                                value={participant.phone}
                                onChange={(e) => handleParticipantChange(index, 'phone', e.target.value)}
                                required
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <div className="input-icon">
                                <FontAwesomeIcon icon={faCalendar} />
                                <Label>Date of Birth</Label>
                              </div>
                              <Input
                                type="date"
                                value={participant.dateOfBirth}
                                onChange={(e) => handleParticipantChange(index, 'dateOfBirth', e.target.value)}
                                required
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <div className="input-icon">
                                <FontAwesomeIcon icon={faTicket} />
                                <Label>Ticket Type</Label>
                              </div>
                              <select
                                className="form-select"
                                value={participant.ticketType}
                                onChange={(e) => handleParticipantChange(index, 'ticketType', e.target.value)}
                                required
                              >
                                <option value="">Select Ticket Type</option>
                                {availableTicketTypes.map(ticketType => (
                                  <option key={ticketType._id} value={ticketType._id}>
                                    {ticketType.name} - ₹{ticketType.price}
                                  </option>
                                ))}
                              </select>
                              <span className="select-arrow"></span>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <div className="input-icon">
                                <FontAwesomeIcon icon={faTshirt} />
                                <Label>T-Shirt Size</Label>
                              </div>
                              <select
                                className="form-select"
                                value={participant.tShirtSize}
                                onChange={(e) => handleParticipantChange(index, 'tShirtSize', e.target.value)}
                              >
                                <option value="XS">Extra Small (XS)</option>
                                <option value="S">Small (S)</option>
                                <option value="M">Medium (M)</option>
                                <option value="L">Large (L)</option>
                                <option value="XL">Extra Large (XL)</option>
                                <option value="XXL">Double XL (XXL)</option>
                              </select>
                              <span className="select-arrow"></span>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <div className="input-icon">
                                <FontAwesomeIcon icon={faUser} />
                                <Label>Gender</Label>
                              </div>
                              <select
                                className="form-select"
                                value={participant.gender}
                                onChange={(e) => handleParticipantChange(index, 'gender', e.target.value)}
                                required
                              >
                                <option value="">Select gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="other">Other</option>
                              </select>
                              <span className="select-arrow"></span>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <div className="input-icon">
                                <FontAwesomeIcon icon={faTag} />
                                <Label>Donation Amount (Optional)</Label>
                              </div>
                              <select
                                className="form-select"
                                value={participant.donation}
                                defaultValue={100}
                                onChange={(e) => handleParticipantChange(index, 'donation', e.target.value)}
                              >
                                <option value="0">₹0</option>
                                <option value="100">₹100</option>
                                <option value="200">₹200</option>
                                <option value="500">₹500</option>
                                <option value="1000">₹1,000</option>
                                <option value="10000">₹10,000</option>
                                <option value="20000">₹20,000</option>
                                <option value="50000">₹50,000</option>
                              </select>
                              <span className="select-arrow"></span>
                            </FormGroup>
                          </Col>
                        </Row>
                        {participants.length > 1 && (
                          <Button
                            color="danger"
                            size="sm"
                            className="remove-participant-btn"
                            onClick={() => handleRemoveParticipant(index)}
                          >
                            Remove
                          </Button>
                        )}
                      </CardBody>
                    </Card>
                  ))}

                  <Button
                    color="link"
                    className="add-participant-btn"
                    onClick={addParticipant}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    Add Another Participant
                  </Button>

                  <FormGroup>
                    <div className="input-icon">
                      <FontAwesomeIcon icon={faTag} />
                      <Label>Coupon Code (Optional)</Label>
                    </div>
                    <Input
                      type="text"
                      placeholder="Enter coupon code"
                      value={couponCode}
                      onChange={(e) => setCouponCode(e.target.value)}
                    />
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>

          <Col lg={4}>
            <div className="summary-sidebar">
              <Card>
                <CardBody>
                  <h3 className="summary-title">
                    <FontAwesomeIcon icon={faReceipt} className="mr-2" />
                    Order Summary
                  </h3>

                  <div className="summary-section">
                    <h4>Event Details</h4>
                    <div className="summary-item">
                      <span>Location</span>
                      <span>{cityDetails?.name || 'Loading...'}</span>
                    </div>
                    <div className="summary-item">
                      <span>Date</span>
                      <span>{cityDetails?.eventDates[0] || 'Loading...'}</span>

                    </div>
                  </div>

                  <div className="summary-section">
                    <h4>Participants</h4>
                    {participants.map((participant, index) => (
                      <div key={index} className="summary-item">
                        <span>
                          {participant.name || `Participant ${index + 1}`}
                          {participant.ticketType && (
                            <Badge className="ticket-type ms-2">
                              {ticketTypes[participant.ticketType].name}
                            </Badge>
                          )}
                        </span>
                        <span>₹{(ticketTypes[participant.ticketType]?.price || 0) + parseInt(participant.donation || 0)}</span>

                      </div>
                    ))}
                  </div>

                  {referralCode && discount > 0 && (
                    <div className="summary-section">
                      <div className="summary-item discount">
                        <span>Referral Discount (10%)</span>
                        <span>-₹{discount}</span>
                      </div>
                    </div>
                  )}


                  <div className="summary-section total">
                    <div className="summary-item">
                      <span>Total Amount</span>
                      <span className="total-amount">₹{totalAmount}</span>
                    </div>
                  </div>

                  <Button
                    color="primary"
                    size="lg"
                    block
                    className="register-btn"
                    onClick={handleSubmit}
                    disabled={loading || !participants.some(p => p.ticketType)}
                  >
                    <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                    {loading ? 'Processing...' : 'Complete Registration'}
                  </Button>

                  <div className="summary-footer">
                    <small>
                      <FontAwesomeIcon icon={faLock} className="me-1" />
                      Secure payment powered by Razorpay
                    </small>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
        
      </Container>
      <Footer />
    </div>
  );
};

export default Register;
