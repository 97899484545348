import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import {TicketService} from '../../../services/api';
import Select from 'react-select';

const EditTicketModal = ({ isOpen, toggle, ticket, onTicketUpdated, cities }) => {
  const [formData, setFormData] = useState({
    participantName: '',
    participantEmail: '',
    participantPhone: '',
    tShirtSize: '',
    status: '',
    cityId: '',
    amount: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (ticket) {
      setFormData({
        participantName: ticket.participantName || '',
        participantEmail: ticket.participantEmail || '',
        participantPhone: ticket.participantPhone || '',
        tShirtSize: ticket.tShirtSize || '',
        status: ticket.status || '',
        cityId: ticket.cityId._id || '',
        price: ticket.price  || ''
      });
    }
  }, [ticket]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const updatedTicket = await TicketService.updateTicket(ticket._id, formData);
      onTicketUpdated(updatedTicket);
      toggle();
    } catch (err) {
      setError(err.message || 'Failed to update ticket');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        Edit Ticket
      </ModalHeader>
      <Form onSubmit={handleSubmit}>
        <ModalBody>
          {error && (
            <Alert color="danger" className="mb-3">
              {error}
            </Alert>
          )}
          
          <FormGroup>
            <Label for="participantName">Name</Label>
            <Input
              type="text"
              name="participantName"
              id="participantName"
              value={formData.participantName}
              onChange={handleInputChange}
              required
            />
          </FormGroup>

          <FormGroup>
            <Label for="participantEmail">Email</Label>
            <Input
              type="email"
              name="participantEmail"
              id="participantEmail"
              value={formData.participantEmail}
              onChange={handleInputChange}
              required
            />
          </FormGroup>

          <FormGroup>
            <Label for="participantPhone">Phone</Label>
            <Input
              type="tel"
              name="participantPhone"
              id="participantPhone"
              value={formData.participantPhone}
              onChange={handleInputChange}
              required
            />
          </FormGroup>

          <FormGroup>
            <Label for="tShirtSize">T-Shirt Size</Label>
            <Input
              type="select"
              name="tShirtSize"
              id="tShirtSize"
              value={formData.tShirtSize}
              onChange={handleInputChange}
              required
            >
              <option value="">Select Size</option>
              <option value="XS">Extra Small (XS)</option>
              <option value="S">Small (S)</option>
              <option value="M">Medium (M)</option>
              <option value="L">Large (L)</option>
              <option value="XL">Extra Large (XL)</option>
              <option value="XXL">Double XL (XXL)</option>
            </Input>
          </FormGroup>

          <FormGroup>
            <Label for="status">Status</Label>
            <Input
              type="select"
              name="status"
              id="status"
              value={formData.status}
              onChange={handleInputChange}
              required
            >
              <option value="">Select Status</option>
              <option value="pending">Pending</option>
              <option value="confirmed">Confirmed</option>
              <option value="cancelled">Cancelled</option>
              <option value="present">Present</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="city">City</Label>
            <Select
              name="city"
              id="city"
              value={cities.find(city => city._id === formData.cityId)}
              onChange={(selectedOption) => setFormData({ ...formData, cityId: selectedOption._id })}
              options={cities}
              getOptionLabel={(city) => city.name}
              required
            ></Select>
          </FormGroup>

          <FormGroup>
            <Label for="amount">Amount</Label>
            <Input
              type="number"
              name="amount"
              id="amount"
              value={formData.price}
              onChange={handleInputChange}
              required
              min="0"
              step="0.01"
            />
          </FormGroup>

        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            <FontAwesomeIcon icon={faTimes} className="me-2" />
            Cancel
          </Button>
          <Button 
            color="primary" 
            type="submit"
            disabled={loading}
          >
            <FontAwesomeIcon icon={faSave} className="me-2" />
            {loading ? 'Saving...' : 'Save Changes'}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default EditTicketModal;
