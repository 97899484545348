import React, { Component, Fragment } from "react";
import cx from "classnames";
import Nav from "../AppNav/VerticalNavWrapper";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import PerfectScrollbar from "react-perfect-scrollbar";
import HeaderLogo from "../AppLogo";
import { NavItem, NavLink } from 'reactstrap';
import { withRouter } from 'react-router-dom';

class AppSidebar extends Component {
  state = {
    items: [
    ]
  };

  render() {
    let {
      backgroundColor,
      enableBackgroundImage,
      backgroundImage,
      backgroundImageOpacity,
    } = this.props;

    const { pathname } = this.props.location;
    const activeItem = pathname.replace("/", "");
    console.log(this.state.items);

    return (
      <Fragment>
        <div className="sidebar-mobile-overlay" onClick={this.toggleMobileSidebar}/>
        <TransitionGroup>
          <CSSTransition component="div"
            className={cx("app-sidebar", backgroundColor, 
              "sidebar-shadow" // Always add sidebar-shadow
            )}
             appear={true} enter={false} exit={false} timeout={500}>
            <div>
              <HeaderLogo />
              <PerfectScrollbar>
                <div className="app-sidebar__inner">
                  <Nav className={cx('sidebar', backgroundColor)}>
                    <TransitionGroup>
                      {this.state.items.map((item, index) => (
                        <CSSTransition key={index} timeout={300} classNames="fade">
                          <NavItem className={cx({ active: activeItem === item.path })}>
                            <NavLink to={item.path} onClick={this.handleClick}>
                              {item.text}
                            </NavLink>
                          </NavItem>
                        </CSSTransition>
                      ))}
                    </TransitionGroup>
                  </Nav>
                </div>
              </PerfectScrollbar>
              <div className={cx("app-sidebar-bg", backgroundImageOpacity)}
                style={{
                  backgroundImage: enableBackgroundImage
                    ? "url(" + backgroundImage + ")"
                    : null,
                }}>
              </div>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Fragment>
    );
  }
}

export default withRouter(AppSidebar);
