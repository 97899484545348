import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faUserFriends,
  faTicketAlt,
  faCity,
  faCog,
  faUserPlus,
  faGift
} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import './PageTitle.scss';

const iconMap = {
  'Dashboard': faHome,
  'Users': faUserFriends,
  'Tickets': faTicketAlt,
  'Cities': faCity,
  'Settings': faCog,
  'Registrations': faUserPlus,
  'Coupon Management': faGift
};

class PageTitle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      heading: props.heading || 'Event Registration',
      subheading: props.subheading || 'Welcome to the Event Registration System',
      icon: props.icon || null
    };
  }

  render() {
    const {
      enablePageTitleIcon = true,
      enablePageTitleSubheading = true,
      className = '',
      iconClassName = '',
      titleClassName = '',
      subtitleClassName = '',
      actions
    } = this.props;

    const icon = this.state.icon || iconMap[this.state.heading] || faHome;
    const iconClasses = classNames('page-title-icon', iconClassName, {
      'd-none': !enablePageTitleIcon || !icon
    });

    return (
      <div className={`app-page-title ${className}`}>
        <div className="page-title-wrapper">
          <div className="page-title-heading">
            <div className={iconClasses}>
              {enablePageTitleIcon && icon && (
                typeof icon === 'string' ?
                  <i className={icon} style={{ fontSize: '2.5rem' }} /> :
                  <FontAwesomeIcon
                    icon={icon}
                    style={{
                      fontSize: '2.5rem',
                      color: '#007bff',
                      opacity: 0.9
                    }}
                  />
              )}
            </div>
            <div>
              <div
                className={`page-title-main ${titleClassName}`}
              >
                {this.state.heading}
              </div>
              {enablePageTitleSubheading && this.state.subheading && (
                <div
                  className={`page-title-subheading ${subtitleClassName}`}
                >
                  {this.state.subheading}
                </div>
              )}
            </div>
          </div>
          {actions && (
            <div className="page-title-actions">
              {actions}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default PageTitle;
