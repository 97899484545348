import React, { useState } from 'react';
import { Card, CardHeader, CardBody, CardFooter, Button, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faEnvelope, faPhone, faTshirt, faRupeeSign, faCalendarAlt, faCheck, faEdit, faPrint, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import EditTicketModal from './EditTicketModal';
import api from '../../../services/api';

const getStatusBadgeColor = (status) => {
  switch (status.toLowerCase()) {
    case 'pending':
      return 'warning';
    case 'confirmed':
      return 'success';
    case 'cancelled':
      return 'danger';
    default:
      return 'primary';
  }
};

const TicketCard = ({ ticket, onMarkEntry, onViewTeam, className, onTicketUpdated, cities }) => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  
  const toggleEditModal = () => {
    setEditModalOpen(!editModalOpen);
  };

  const handleDelete = async (ticket) => {
    if (window.confirm('Are you sure you want to delete this ticket?')) {
      try {
        await api.deleteTicket(ticket._id);
        // Refresh the ticket list
        if (onTicketUpdated) {
          onTicketUpdated();
        }
      } catch (error) {
        console.error('Error deleting ticket:', error);
        alert('Failed to delete ticket');
      }
    }
  };

  return (
    <>
      <Card className={"ticket-card " + ticket.status.toLowerCase() + (ticket.status === 'present' ? ' bg-success-light' : '') + ' ' + className}  >
        <div 
          className="ticket-type-bar" 
          style={{ 
            backgroundColor: ticket.ticketType?.color,
            height: '4px'
          }} 
        />
        <CardHeader className="d-flex justify-content-between align-items-center">
          <h5 className="mb-0">{ticket.participantName}</h5>
          <Badge color={getStatusBadgeColor(ticket.status)}>{ticket.status}</Badge>
        </CardHeader>
        <CardBody>
          <div className="ticket-details">
            <div className="detail-badges">
              <Badge color="light" className="detail-badge">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="me-2" />
                {ticket.cityId?.name}
              </Badge>
              <Badge color="light" className="detail-badge">
                <FontAwesomeIcon icon={faEnvelope} className="me-2" />
                {ticket.participantEmail}
              </Badge>
              <Badge color="light" className="detail-badge">
                <FontAwesomeIcon icon={faPhone} className="me-2" />
                {ticket.participantPhone}
              </Badge>
              <Badge color="light" className="detail-badge">
                <FontAwesomeIcon icon={faTshirt} className="me-2" />
                {ticket.tShirtSize}
              </Badge>
              <Badge color="light" className="detail-badge">
                <FontAwesomeIcon icon={faRupeeSign} className="me-2" />
                {ticket.price}
              </Badge>
              <Badge color="light" className="detail-badge">
                <FontAwesomeIcon icon={faCalendarAlt} className="me-2" />
                {new Date(ticket.createdAt).toLocaleDateString()}
              </Badge>
            </div>
          </div>
        </CardBody>
        <CardFooter className="d-flex justify-content-between align-items-center">
          <Button color="outline-success" onClick={() => onMarkEntry(ticket)}>
            <FontAwesomeIcon icon={faCheck} className="me-2" /> Mark Entry
          </Button>
          <div>
            {ticket.teamSize > 1 && (
              <Button color="link" onClick={() => onViewTeam(ticket)}>
                {ticket.teamSize}
              </Button>
            )}
            <Button color="link" onClick={toggleEditModal}>
              <FontAwesomeIcon icon={faEdit} className="me-2" />
            </Button>
            <Button color="link" className="text-danger" onClick={() => handleDelete(ticket)}>
              <FontAwesomeIcon icon={faBan} className="me-2" />
            </Button>
          </div>
        </CardFooter>
      </Card>

      <EditTicketModal
        isOpen={editModalOpen}
        toggle={toggleEditModal}
        ticket={ticket}
        cities={cities}
        onTicketUpdated={(updatedTicket) => {
          if (onTicketUpdated) {
            onTicketUpdated(updatedTicket);
          }
        }}
      />
    </>
  );
};

export default TicketCard;