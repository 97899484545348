import React, { useState, useEffect } from 'react';
import { Container, Card, CardBody, Spinner, Alert } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { CityService } from '../../services/api';
import Header from '../Header.js'; 
import Footer from '../../components/Footer/Footer'; 
import './CitySelection.scss';

const CitySelection = () => {
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const history = useHistory();
  const eventDate = '23-02-2025';

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const fetchedCities = await CityService.getAllCities();
        setCities(fetchedCities);
        setLoading(false);
      } catch (err) {
        setError(err.message || 'Failed to fetch cities');
        setLoading(false);
      }
    };

    fetchCities();
  }, []);

  const handleCitySelect = (cityId) => {
    history.push(`/register/${cityId}`);
  };

  if (loading) {
    return (
      <div className="city-selection-page text-center">
        <Spinner color="primary" />
        <p>Loading cities...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="city-selection-page">
        <Alert color="danger">{error}</Alert>
      </div>
    );
  }

  return (
    <div className="city-selection-page">
      <Header />
      <Container>
        <h1 className="text-center">Choose the location where you want to participate</h1>
        <div className="city-grid">
          {cities.slice().sort((a,b)=>a.name.localeCompare(b.name)).map((city) => (
            <Card 
              key={city._id} 
              className="city-card" 
              onClick={() => handleCitySelect(city._id)}
            >
              <div className="city-image-container">
                <img 
                  src={city.imageUrl || '/images/cities/default-city.jpg'} 
                  alt={city.name} 
                  className="city-image" 
                  onError={(e) => {
                    e.target.src = '/images/cities/default-city.jpg';
                  }}
                />
              </div>
              <CardBody>
                <h3>{city.name}</h3>
                <div className="date">
                  <i className="pe-7s-calendar"></i>
                  {eventDate}
                </div>
              </CardBody>
            </Card>
          ))}
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default CitySelection;
