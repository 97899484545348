import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Badge,
  Progress
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTicketAlt,
  faCity
} from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import api from '../../../services/api';
import './Dashboard.scss';

const CityDashboard = () => {
  const [cityStats, setCityStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await api.get('/dashboard');
        setCityStats(response.data);
      } catch (err) {
        setError(err.message || 'Failed to fetch dashboard data');
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  const getTicketTypeColor = (type) => {
    switch (type) {
      case 'regular': return 'primary';
      case 'vip': return 'success';
      case 'student': return 'warning';
      default: return 'secondary';
    }
  };

  const calculatePercentage = (value, total) => {
    return Math.round((value / total) * 100);
  };

  if (loading) {
    return <Container fluid>Loading dashboard data...</Container>;
  }

  if (error) {
    return <Container fluid>Error: {error}</Container>;
  }

  return (
    <Container fluid className="city-dashboard">
      <Row className="mb-4">
        <Col>
          <PageTitle
            heading="City Ticket Dashboard"
            subheading="Overview of ticket sales across different cities"
            icon={faCity}
            titleClassName="text-primary"
            subtitleClassName="text-muted"
            iconClassName="bg-transparent"
          />
        </Col>
      </Row>

      <Row>
        {cityStats.map((city, index) => (
          <Col key={index} md={6} lg={4} className="mb-4">
            <Card className="city-stats-card">
              <div
                className="city-image-overlay"
                style={{ backgroundImage: `url(${city.image})` }}
              />
              <CardBody>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h4 className="mb-0">{city.city}</h4>
                  <Badge color="light" className="text-dark">
                    <FontAwesomeIcon icon={faTicketAlt} className="me-1" />
                    {city.totalTickets}
                  </Badge>
                </div>

                <div className="stats-section mt-3">
                  <div className="d-flex justify-content-between mb-2">
                    <span>Total Revenue</span>
                    <strong>₹{city.totalAmount.toLocaleString()}</strong>
                  </div>
                  <div className="ticket-type-breakdown">
                    {Object.entries(city.ticketTypes).map(([type, count]) => (
                      <div key={type} className="mb-2">
                        <div className="d-flex justify-content-between">
                          <span className="text-capitalize">{type} Tickets</span>
                          <span>{count} ({calculatePercentage(count, city.totalTickets)}%)</span>
                        </div>
                        <Progress
                          value={calculatePercentage(count, city.totalTickets)}
                          color={getTicketTypeColor(type)}
                          className="mt-1"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default CityDashboard;
