import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Row, 
  Col, 
  Card, 
  CardBody, 
  CardHeader,
  Form, 
  FormGroup, 
  Label, 
  Input, 
  Button,
  Spinner,
  Alert,
  Toast,
  ToastBody,
  ToastHeader
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import { TicketService } from '../../../services/api';
import './Settings.scss';

const Settings = () => {
  const [settings, setSettings] = useState({
    eventName: 'OrindIndia Event 2025',
    maxParticipantsPerRegistration: 5,
    referralDiscountPercentage: 10,
    enableReferralSystem: true,
    ticketTypes: []
  });
  const [ticketTypes, setTicketTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newTicketType, setNewTicketType] = useState({ name: '', price: '', color: '#000000' });
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    const fetchTicketTypes = async () => {
      try {
        const fetchedTicketTypes = await TicketService.getAllTicketTypes();
        setTicketTypes(fetchedTicketTypes);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch ticket types');
        setLoading(false);
      }
    };

    fetchTicketTypes();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSettings(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleTicketTypeChange = (index, field, value) => {
    const updatedTicketTypes = [...ticketTypes];
    updatedTicketTypes[index][field] = value;
    setTicketTypes(updatedTicketTypes);
  };

  const handleAddTicketType = async () => {
    if (!newTicketType.name || !newTicketType.price) {
      setError('Please fill in both ticket type and price');
      return;
    }

    try {
      const createdTicketType = await TicketService.createTicketType(newTicketType);
      setTicketTypes([...ticketTypes, createdTicketType]);
      setNewTicketType({ name: '', price: '', color: '#000000' });
      setError(null);
    } catch (err) {
      setError('Failed to create ticket type');
    }
  };

  const handleUpdateTicketType = async (index) => {
    try {
      await TicketService.updateTicketType(ticketTypes[index]._id, ticketTypes[index]);
      setError(null);
    } catch (err) {
      setError('Failed to update ticket type');
    }
  };

  const handleDeleteTicketType = async (index) => {
    try {
      await TicketService.deleteTicketType(ticketTypes[index].id);
      setTicketTypes(ticketTypes.filter((ticket, i) => i !== index));
      setError(null);
    } catch (err) {
      setError('Failed to delete ticket type');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Implement save settings logic if needed
      console.log('Settings saved:', settings);
      setShowToast(true);
      setTimeout(() => setShowToast(false), 3000); // Hide toast after 3 seconds
    } catch (err) {
      setError('Failed to save settings');
    }
  };

  if (loading) {
    return (
      <Container fluid className="settings-page text-center">
        <Spinner color="primary" />
      </Container>
    );
  }

  return (
    <Container fluid className="settings-page">
      <Row className="mb-4">
        <Col>
          <PageTitle
            heading="Event Settings"
            subheading="Configure event details and preferences"
            icon={faCog}
            titleClassName="text-primary"
            subtitleClassName="text-muted"
            iconClassName="bg-transparent"
          />
        </Col>
      </Row>
      {error && (
        <Row>
          <Col>
            <Alert color="danger">{error}</Alert>
          </Col>
        </Row>
      )}
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label>Event Name</Label>
                  <Input
                    type="text"
                    name="eventName"
                    value={settings.eventName}
                    onChange={handleInputChange}
                  />
                </FormGroup>

                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Max Participants per Registration</Label>
                      <Input
                        type="number"
                        name="maxParticipantsPerRegistration"
                        value={settings.maxParticipantsPerRegistration}
                        onChange={handleInputChange}
                        min={1}
                        max={10}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Referral Discount Percentage</Label>
                      <Input
                        type="number"
                        name="referralDiscountPercentage"
                        value={settings.referralDiscountPercentage}
                        onChange={handleInputChange}
                        min={0}
                        max={50}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      name="enableReferralSystem"
                      checked={settings.enableReferralSystem}
                      onChange={(e) => setSettings(prev => ({
                        ...prev,
                        enableReferralSystem: e.target.checked
                      }))}
                    />{' '}
                    Enable Referral System
                  </Label>
                </FormGroup>

                <Card className="settings-card mt-4">
                  <CardHeader>
                    <h3 className="mb-0">Ticket Types</h3>
                  </CardHeader>
                  <CardBody>
                    <div className="ticket-types-list">
                      {ticketTypes.map((ticket, index) => (
                        <div key={index} className="ticket-type-row">
                          <Row className="align-items-center mb-3">
                            <Col md={4}>
                              <FormGroup className="mb-0">
                                <Label>Ticket Type</Label>
                                <Input
                                  type="text"
                                  value={ticket.name}
                                  onChange={(e) => handleTicketTypeChange(index, 'name', e.target.value)}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={3}>
                              <FormGroup className="mb-0">
                                <Label>Price</Label>
                                <Input
                                  type="number"
                                  value={ticket.price}
                                  onChange={(e) => handleTicketTypeChange(index, 'price', e.target.value)}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={3}>
                              <FormGroup className="mb-0">
                                <Label>Color</Label>
                                <Input
                                  type="color"
                                  value={ticket.color}
                                  className="form-control-color w-100"
                                  onChange={(e) => handleTicketTypeChange(index, 'color', e.target.value)}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={2} className="d-flex justify-content-end align-items-end">
                              <div className="button-group">
                                <Button 
                                  color="warning" 
                                  className="me-2"
                                  onClick={() => handleUpdateTicketType(index)}
                                >
                                  Update
                                </Button>
                                <Button 
                                  color="danger"
                                  onClick={() => handleDeleteTicketType(index)}
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      ))}

                      <div className="new-ticket-type mt-4">
                        <h4 className="mb-3">Add New Ticket Type</h4>
                        <Row className="align-items-end">
                          <Col md={4}>
                            <FormGroup>
                              <Label>Ticket Type</Label>
                              <Input
                                type="text"
                                placeholder="Type (e.g., Regular)"
                                value={newTicketType.name}
                                onChange={(e) => setNewTicketType(prev => ({
                                  ...prev,
                                  name: e.target.value
                                }))}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={3}>
                            <FormGroup>
                              <Label>Price</Label>
                              <Input
                                type="number"
                                placeholder="Price"
                                value={newTicketType.price}
                                onChange={(e) => setNewTicketType(prev => ({
                                  ...prev,
                                  price: e.target.value
                                }))}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={3}>
                            <FormGroup>
                              <Label>Color</Label>
                              <Input
                                type="color"
                                className="form-control-color w-100"
                                value={newTicketType.color}
                                onChange={(e) => setNewTicketType(prev => ({
                                  ...prev,
                                  color: e.target.value
                                }))}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={2} className="d-flex justify-content-end">
                            <Button 
                              color="primary" 
                              onClick={handleAddTicketType}
                              className="w-100"
                            >
                              <FontAwesomeIcon icon={faPlus} className="me-2" />
                              Add
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </CardBody>
                </Card>

                <div className="d-flex justify-content-end mt-4">
                  <Button 
                    color="success" 
                    size="lg"
                    onClick={handleSubmit}
                    className="px-4"
                  >
                    Save Settings
                  </Button>
                </div>

                {showToast && (
                  <div className="toast-container">
                    <Toast className="bg-success text-white">
                      <ToastHeader className="bg-success text-white">
                        Success
                      </ToastHeader>
                      <ToastBody>
                        Settings saved successfully!
                      </ToastBody>
                    </Toast>
                  </div>
                )}
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Settings;
