import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  ButtonGroup,
  Table,
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Label,
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faFilter,
  faDownload,
  faEllipsisV,
  faSync,
  faTicketAlt,
  faClipboardList,
  faSignOutAlt
} from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import './Registrations.scss';
import AuthService from '../../../services/authService';
import { RegistrationService, CityService, TicketService } from '../../../services/api';
import TeamModal from '../Tickets/TeamModal';
import { useHistory } from 'react-router-dom';

const Registrations = () => {
  const history = useHistory();
  const [filters, setFilters] = useState({
    search: '',
    city: '',
    ticketType: '',
    status: '',
    startDate: null,
    endDate: null,
  });

  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const [selectedRegistration, setSelectedRegistration] = useState(null);
  const [isTicketModalOpen, setIsTicketModalOpen] = useState(false);
  const [registrations, setRegistrations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [cities, setCities] = useState([]);
  const [ticketTypes, setTicketTypes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [viewTeamModal, setViewTeamModal] = useState(false);
  const [teamTickets, setTeamTickets] = useState([]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPaginationItems = () => {
    const items = [];
    const maxPagesToShow = 5;
    let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    if (endPage - startPage + 1 < maxPagesToShow) {
      startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }

    // Previous button
    items.push(
      <PaginationItem key="prev" disabled={currentPage === 1}>
        <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)} />
      </PaginationItem>
    );

    // First page
    if (startPage > 1) {
      items.push(
        <PaginationItem key={1}>
          <PaginationLink onClick={() => handlePageChange(1)}>1</PaginationLink>
        </PaginationItem>
      );
      if (startPage > 2) {
        items.push(<PaginationItem key="ellipsis1" disabled><PaginationLink>...</PaginationLink></PaginationItem>);
      }
    }

    // Page numbers
    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <PaginationItem key={i} active={currentPage === i}>
          <PaginationLink onClick={() => handlePageChange(i)}>{i}</PaginationLink>
        </PaginationItem>
      );
    }

    // Last page
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        items.push(<PaginationItem key="ellipsis2" disabled><PaginationLink>...</PaginationLink></PaginationItem>);
      }
      items.push(
        <PaginationItem key={totalPages}>
          <PaginationLink onClick={() => handlePageChange(totalPages)}>{totalPages}</PaginationLink>
        </PaginationItem>
      );
    }

    // Next button
    items.push(
      <PaginationItem key="next" disabled={currentPage === totalPages}>
        <PaginationLink next onClick={() => handlePageChange(currentPage + 1)} />
      </PaginationItem>
    );

    return items;
  };

  useEffect(() => {
    const fetchCities = async () => {
      const cities = await CityService.getAllCities();
      setCities(cities);
    };
    const fetchTicketTypes = async () => {
      const ticketTypes = await RegistrationService.getTicketTypes();
      setTicketTypes(ticketTypes);
    };
    fetchCities();
    fetchTicketTypes();
  }, []);

  useEffect(() => {
    const fetchRegistrations = async () => {
      setLoading(true);
      setError(null);
      try {
        const pageSize = itemsPerPage;
        const response = await RegistrationService.getRegistrations(filters, currentPage, pageSize);
        setRegistrations(response.registrations);
        setTotalPages(Math.ceil(response.total / pageSize));
      } catch (err) {
        setError(err.message || 'Failed to fetch registrations');
      } finally {
        setLoading(false);
      }
    };

    fetchRegistrations();
  }, [currentPage, filters, itemsPerPage]);

  const statuses = ['Confirmed', 'Pending', 'Cancelled'];

  const handleFilterChange = (field, value) => {
    setFilters(prev => ({ ...prev, [field]: value }));
  };

  const handleExport = () => {
    // Implement CSV export functionality
  };

  const handleRefresh = () => {
    // Implement data refresh functionality
  };

  const getStatusBadgeColor = (status) => {
    switch (status.toLowerCase()) {
      case 'confirmed': return 'success';
      case 'pending': return 'warning';
      case 'cancelled': return 'danger';
      default: return 'primary';
    }
  };

  const viewTeam = async (registrationId) => {
    try {
      const response = await TicketService.getTickets({
        registrationId: registrationId
      });
      setTeamTickets(response.tickets);
      setViewTeamModal(true);
    } catch (error) {
      console.error('Error fetching team tickets:', error);
    }
  };

  const toggleTicketModal = (registration) => {
    setSelectedRegistration(registration);
    setViewTeamModal(!isTicketModalOpen);
  };

  const handleLogout = () => {
    AuthService.logout();
    history.push('/login');
  };

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <Container fluid className="registrations-page">
      <Row className="mb-3">
        <Col>
          <PageTitle
            heading="Registrations"
            subheading="Manage event registrations"
            icon={faClipboardList}
          />
        </Col>
        <Col xs="auto">
          <Button color="danger" onClick={handleLogout}>
            <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />
            Logout
          </Button>
        </Col>
      </Row>

      <Card className="mb-3">
        <CardBody>
          {loading && <div className="spinner"></div>}
          <Row className="align-items-center mb-3">
            <Col md={6}>
              <div className="search-box">
                <Input
                  type="text"
                  placeholder="Search registrations..."
                  value={filters.search}
                  onChange={(e) => handleFilterChange('search', e.target.value)}
                />
                <FontAwesomeIcon icon={faSearch} className="search-icon" />
              </div>
            </Col>
            <Col md={6} className="text-right">
              <ButtonGroup>
                <Button
                  color="secondary"
                  onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
                >
                  <FontAwesomeIcon icon={faFilter} className="mr-2" />
                  Filters
                </Button>
                <Button color="secondary" onClick={handleExport}>
                  <FontAwesomeIcon icon={faDownload} className="mr-2" />
                  Export
                </Button>
                <Button color="secondary" onClick={handleRefresh}>
                  <FontAwesomeIcon icon={faSync} className="mr-2" />
                  Refresh
                </Button>
              </ButtonGroup>
            </Col>
          </Row>

          {showAdvancedFilters && (
            <Row className="mb-3">
              <Col md={3}>
                <FormGroup>
                  <Label>City</Label>
                  <Input
                    type="select"
                    value={filters.city}
                    onChange={(e) => handleFilterChange('city', e.target.value)}
                  >
                    <option value="">All Cities</option>
                    {cities.map(city => (
                      <option key={city._id} value={city._id}>{city.name}</option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Ticket Type</Label>
                  <Input
                    type="select"
                    value={filters.ticketType}
                    onChange={(e) => handleFilterChange('ticketType', e.target.value)}
                  >
                    <option value="">All Types</option>
                    {ticketTypes.map(type => (
                      <option key={type._id} value={type.name}>{type.name}</option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Status</Label>
                  <Input
                    type="select"
                    value={filters.status}
                    onChange={(e) => handleFilterChange('status', e.target.value)}
                  >
                    <option value="">All Statuses</option>
                    {statuses.map(status => (
                      <option key={status} value={status}>{status}</option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Registration Date</Label>
                  <div className="d-flex">
                    <DatePicker
                      selected={filters.startDate}
                      onChange={date => handleFilterChange('startDate', date)}
                      selectsStart
                      startDate={filters.startDate}
                      endDate={filters.endDate}
                      className="form-control"
                      placeholderText="Start Date"
                    />
                    <DatePicker
                      selected={filters.endDate}
                      onChange={date => handleFilterChange('endDate', date)}
                      selectsEnd
                      startDate={filters.startDate}
                      endDate={filters.endDate}
                      minDate={filters.startDate}
                      className="form-control ml-2"
                      placeholderText="End Date"
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>
          )}

          <Table responsive>
            <thead>
              <tr>
                <th>Registration ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>City</th>
                <th>Tickets</th>
                <th>Status</th>
                <th>Amount</th>
                <th>Donation</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {registrations.map(reg => (
                <tr key={reg._id}>
                  <td>{reg._id}</td>
                  <td>{reg.ticketIds[0]?.participantName}</td>
                  <td>{reg.ticketIds[0]?.participantEmail}</td>
                  <td>{reg.cityId?.name}</td>
                  <td>
                    <Button
                      color="link"
                      onClick={() => viewTeam(reg._id)}
                      className="p-0"
                    >
                      <Badge color="primary">
                        {reg.ticketIds.length} <FontAwesomeIcon icon={faTicketAlt} />
                      </Badge>
                    </Button>
                  </td>
                  <td>
                    <Badge color={getStatusBadgeColor(reg.status)}>
                      {reg.status}
                    </Badge>
                  </td>
                  <td>₹{reg.totalAmount}</td>
                  <td>₹{reg.donationAmount}</td>
                  <td>
                    <UncontrolledDropdown>
                      <DropdownToggle tag="span" className="text-muted cursor-pointer">
                        <FontAwesomeIcon icon={faEllipsisV} />
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem>View Details</DropdownItem>
                        <DropdownItem>Edit</DropdownItem>
                        <DropdownItem>Cancel Registration</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <Row className="mt-4">
            <Col className="d-flex justify-content-between align-items-center">
              <div>
                <span className="me-2">Items per page:</span>
                <Input
                  type="select"
                  value={itemsPerPage}
                  onChange={(e) => setItemsPerPage(Number(e.target.value))}
                  style={{ width: 'auto', display: 'inline-block' }}
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                </Input>
              </div>
              <Pagination>
                {renderPaginationItems()}
              </Pagination>
            </Col>
          </Row>
        </CardBody>
      </Card>

      {loading && (
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )}

        <TeamModal
          registration={selectedRegistration}
          isOpen={viewTeamModal}
          toggle={() => setViewTeamModal(!viewTeamModal)}
          teamTickets={teamTickets}
          onMarkEntry={toggleTicketModal}
          onViewTeam={viewTeam}
          onCancel={toggleTicketModal}
          onEdit={toggleTicketModal}
          getStatusBadgeColor={getStatusBadgeColor}
        />
      
    </Container>
  );
};

export default Registrations;
