import React from 'react';
import { Link } from 'react-router-dom';
import './Header.scss';

const Header = () => {
  return (
    <header className="header">
       <img src="/images/logo.png" width={200} alt="logo" />
    </header>
  );
};

export default Header;
