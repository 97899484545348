import "./polyfills";
import React from "react";
import { createRoot } from 'react-dom/client';
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./assets/base.scss";
import Login from "./Pages/Public/Login";
import CitySelection from "./Pages/Public/CitySelection";
import AppMain from "./Layout/AppMain";
import Register from "./Pages/Public/Register";
import RegistrationSuccess from "./Pages/Public/RegistrationSuccess";

const rootElement = document.getElementById("root");

const renderApp = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route path="/" component={AppMain} />

      <Route exact path="/register/:cityId" component={Register} />
      <Route exact path="/registration-success" component={RegistrationSuccess} />
    </Switch>
  </BrowserRouter>
);

const root = createRoot(rootElement).render(renderApp());

if (module.hot) {
  module.hot.accept("./Pages/Public/Login", () => {
    const NextApp = require("./Pages/Public/Login").default;
    root.render(renderApp(NextApp));
  });
}

serviceWorker.unregister();
