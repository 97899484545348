import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { MainNav, AdminNav } from './NavItems';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import './VerticalNav.scss';

class VerticalNavWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      closedSidebar: false,
      enableMobileMenu: false,
      items: [...MainNav, ...AdminNav]
    };
  }

  handleClick = (path) => {
    this.props.history.push(path);
  }

  render() {
    const { pathname } = this.props.location;
    const activeItem = pathname;

    return (
      <Fragment>
        <h5 className="app-sidebar__heading">Menu</h5>
        <Nav className="vertical-nav">
          {this.state.items.map((item, index) => (
            <NavItem key={index} className={cx({ active: activeItem === item.to })}>
              <NavLink 
                tag="a" 
                href="#" 
                onClick={(e) => {
                  e.preventDefault();
                  this.handleClick(item.to);
                }}
              >
                {item.icon && <FontAwesomeIcon icon={item.icon} className="me-2" />}
                {item.label}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </Fragment>
    );
  }
}

export default withRouter(VerticalNavWrapper);
