import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardHeader, CardBody, Container } from 'reactstrap';
import { DonationService } from '../../services/api';
import PageTitle from '../../Layout/AppMain/PageTitle';
const Donations = () => {
  const [donations, setDonations] = useState([]);
  const [modal, setModal] = useState(false);
  const [currentDonation, setCurrentDonation] = useState(null);

  useEffect(() => {
    fetchDonations();
  }, []);

  const fetchDonations = async () => {
    // Fetch donations from API
    const response = await DonationService.getDonations();
    setDonations(response.data);
  };

  const toggleModal = () => setModal(!modal);

  const handleSave = async () => {

  };

  const handleDelete = async (id) => {
    await fetch(`/api/donations/${id}`, { method: 'DELETE' });
    fetchDonations();
  };


  return (

    <Container fluid className="main-content-container px-4 py-4">
      <PageTitle
        heading="Donations Management"
        subheading="View and manage donations"
        icon="pe-7s-ticket icon-gradient bg-mean-fruit"
      />
      <Card className="main-card mb-3">
        <CardHeader>
        <Button onClick={toggleModal}>Add Donation</Button>
        </CardHeader>
        <CardBody>
        <Table>
          <thead>
            <tr>
              <th>Receipt Number</th>
              <th>Name</th>
              <th>Email</th>
              <th>Amount</th>
              <th>Date</th>
              <th>Place  </th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {donations.map(donation => (
              <tr key={donation.id}>
                <td>{donation.receiptNumber}</td>
                <td>{donation.donorName}</td>
                <td>{donation.donorEmail}</td>
                <td>{donation.amount}</td>
                <td>{donation.createdAt}</td>
                <td>{donation.cityName}</td>
                <td>
                  <Button onClick={() => { setCurrentDonation(donation); toggleModal(); }}>Edit</Button>
                  <Button onClick={() => handleDelete(donation.id)}>Delete</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        </CardBody>
        <Modal isOpen={modal} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Donation</ModalHeader>
          <ModalBody>
            {/* Form for donation details */}
            <input type="text" placeholder="Amount" value={currentDonation?.amount || ''} onChange={(e) => setCurrentDonation({ ...currentDonation, amount: e.target.value })} />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleSave}>Save</Button>{' '}
            <Button color="secondary" onClick={toggleModal}>Cancel</Button>
          </ModalFooter>
        </Modal>

      </Card>
    </Container>
  );
};

export default Donations;
